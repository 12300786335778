import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Text } from '../context/LanguageCtx'

import Box from "@material-ui/core/Box";

import '../assets/css/notFound.scss'

function NotFoundPage() {

  useEffect(() => {
    document.title = 'Not found'
  }, [])
  
  return (
    <Box id="NotFound" className="page fullScreen content-dark t-center">
      <Box className="page-wrapper fullHeight center pos-rel">
        <Box className="heading-wrapper pos-abs fullWidth">
          <Box className="heading t-fancy">4<span className="t-red">O</span>4</Box>
          <Box className="ops-txt t-fancy t-red center">Ops!</Box>
        </Box>
        <Box className="content-wrapper pos-abs fullWidth">
          <Box><Text tid="notFound.content"/></Box>
          <Box><Text tid="notFound.backTo"/> <Link className="home-txt t-red t-bold" to='/home'><Text tid="home"/></Link></Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NotFoundPage